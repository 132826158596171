import { branchUrl, getHeader } from "@/constant/config"
import axios from 'axios'

export default {
  state: {
    activeModalBranch: false,
    activeModalBranchEdit: false,
    branchList: [],
    branchData: {}
  },
  mutations: {
    SET_ACTIVE_MODAL_BRANCH (state, obj) {
      state.activeModalBranch = obj
    },
    SET_ACTIVE_MODAL_BRANCH_EDIT (state, obj) {
      state.activeModalBranchEdit = obj
    },
    SET_BRANCH_LIST (state, obj) {
      state.branchList = obj
    },
    SET_BRANCH (state, obj) {
      state.branchData = obj
    }
  },
  actions: {
    activeModalBranch ({ commit }) {
      commit('SET_ACTIVE_MODAL_BRANCH', true)
    },
    deactiveModalBranch ({ commit }) {
      commit('SET_ACTIVE_MODAL_BRANCH', false)
    },
    activeModalBranchEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_BRANCH_EDIT', true)
    },
    deactiveModalBranchEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_BRANCH_EDIT', false)
    },
    async getBranchList ({ commit }) {
      try {
        const data = await axios.get(branchUrl, { headers: getHeader() })
        commit('SET_BRANCH_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getBranch ({ commit }, payload) {
      try {
        const data = await axios.get(branchUrl + '/' + payload.id, { headers: getHeader() })
        commit('SET_BRANCH', data.data.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
