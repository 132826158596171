import { getHeader, locationUrl } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    locationList: []
  },
  mutations: {
    SET_LOCATION_LIST (state, obj) {
      state.locationList = obj
    }
  },
  actions: {
    async getLocationList ({ commit }) {
      try {
        const data = await axios.get(locationUrl, { headers: getHeader() })
        commit('SET_LOCATION_LIST', data.data.data)
        console.log(data.data.message)
        console.log(data.data.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
  