import { getHeader, unitUrl } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    unitList: [],
    unitTypeList: [],
    unitCheckOptionList: [],
    activeModalUnit: false,
    unitListCheck: []
  },
  getters: {
    unitSelect: (state) => {
      const array = state.unitTypeList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        loop.push({
          value: array[i].id,
          label: array[i].name
        })
      }
      return loop
    },
    unitCheckOptionList: (state) => state.unitCheckOptionList,
    unitList: (state) => state.unitList
  },
  mutations: {
    SET_UNIT_TYPE_LIST (state, obj) {
      state.unitTypeList = obj
    },
    SET_UNIT_CHECK_OPTION (state, obj) {
      state.unitCheckOptionList = obj
    },
    SET_UNIT_LIST_CHECK (state, obj) {
      state.unitListCheck = obj
    },
    openModalUnit (state) {
      state.activeModalUnit = true
    },
    closeModalUnit (state) {
      state.activeModalUnit = false
    },
    SET_UNIT_LIST (state, obj) {
      state.unitList = obj
    }
  },
  actions: {
    openModalUnit ({ commit }) {
      commit('openModalUnit')
    },
    closeModalUnit ({ commit }) {
      commit('closeModalUnit')
    },
    async getUnitTypeList ({ commit }) {
      try {
        const data = await axios.get(unitUrl + '/type', { headers: getHeader() })
        console.log('getUnitTypeList')
        console.log(data)
        commit('SET_UNIT_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getUnitCheckOptionList ({ commit }) {
      try {
        const data = await axios.get(unitUrl + '/check/option', { headers: getHeader() })
        commit('SET_UNIT_CHECK_OPTION', data.data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getUnitListCheck ({ commit }, payload) {      
      try {
        const date = payload.date ? payload.date : null
        console.log(unitUrl + '/list-check?date=' + date)
        const data = await axios.get(unitUrl + '/list-check?date=' + date, { headers: getHeader() })
        commit('SET_UNIT_LIST_CHECK', data.data.data)
        console.log(data.data.message)
      } catch (error) {
        console.log(error)
      }
    },
    async getUnitList ({ commit }) {      
      try {
        const data = await axios.get(unitUrl, { headers: getHeader() })
        commit('SET_UNIT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
  