import { bankUrl, branchUrl, getHeader, organizationUrl, paymentMethodUrl, settingUrl, taxUrl, timeoutAPP, uomUrl, warehouseUrl } from "@/constant/config"
import axios from "axios"

export default {
  state: {
    uomList: [],
    uomCategoryList: [],
    uomTypeList: [],
    warehouseList: [],
    taxList: [],
    settingList: [{
      id: null,
      name: null,
      active: false,
      ref_no: null,
      description: null,
      org_no: null
    }],
    organizationList: [],
    paymentMethodCategoryList: [],
    paymentMethodList: [],
    bankList: [],
    branchList: [],
    showBranchFormModal: false
  },
  mutations: {
    SET_BRANCH_LIST (state, obj) {
      state.branchList = obj
    },
    SET_BANK_LIST (state, obj) {
      state.bankList = obj
    },
    SET_SETTING_LIST (state, obj) {
      state.settingList = obj
    },
    SET_TAX_LIST (state, obj) {
      state.taxList = obj
    },
    SET_WAREHOUSE_LIST (state, obj) {
      state.warehouseList = obj
    },
    SET_UOM_LIST (state, obj) {
      state.uomList = obj
    },
    SET_UOM_CATEGORY_LIST (state, obj) {
      state.uomCategoryList = obj
    },
    SET_UOM_TYPE_LIST (state, obj) {
      state.uomTypeList = obj
    },
    SET_ORGANIZATION_LIST (state, obj) {
      state.organizationList = obj
    },
    SET_PURCHASE_METHOD_CATEGORY_LIST (state, obj) {
      state.paymentMethodCategoryList = obj
    },
    SET_PAYMENT_METHOD_LIST (state, obj) {
      state.paymentMethodList = obj
    },
    SET_SHOW_BRANCH_FORM_MODAL (state, obj) {
      state.showBranchFormModal = obj 
    }
  },
  actions: {
    enableBranchFormModal ({ commit }) {
      commit('SET_SHOW_BRANCH_FORM_MODAL', true)
    },
    disableBranchFormModal ({ commit }) {
      commit('SET_SHOW_BRANCH_FORM_MODAL', false)
    },
    async getBranchList ({ commit }) {
      try {
        const data = await axios.get(branchUrl, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_BRANCH_LIST', data.data.data)
      } catch(error) {
        console.log(error)
        console.log(error.resopnse)
      }
    },
    async getBankList ({ commit }) {
      try {
        const data = await axios.get(bankUrl, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_BANK_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.resopnse)
      }
    },
    async getSettingList ({ commit }) {
      console.log(settingUrl)
      console.log(getHeader())
      try {
        const data = await axios.get(settingUrl, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_SETTING_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getTaxList ({ commit }) {
      try {
        const data = await axios.get(taxUrl, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_TAX_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getWarehouseList ({ commit }) {
      try {
        const data = await axios.get(warehouseUrl, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_WAREHOUSE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getUomList ({ commit }) {
      try {
        const data = await axios.get(uomUrl, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_UOM_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getUomCategoryList ({ commit }) {
      try {
        const data = await axios.get(uomUrl + '/category', { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_UOM_CATEGORY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getUomTypeList ({ commit }) {
      try {
        const data = await axios.get(uomUrl + '/type', { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_UOM_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getOrganizationList ({ commit }) {
      try {
        const data = await axios.get(organizationUrl, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_ORGANIZATION_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getPaymentMethodCategoryList ({ commit }) {
      try {
        const data = await axios.get(paymentMethodUrl + '/category', { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_PURCHASE_METHOD_CATEGORY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
      }
    },
    async getPaymentMethodList ({ commit }) {
      try {
        const data = await axios.get(paymentMethodUrl, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_PAYMENT_METHOD_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    }
  },
  getters: {
    settingList: (state) => state.settingList,
    warehouseList: (state) => state.warehouseList,
    uomList: (state) => state.uomList,
    uomCategoryList: (state) => state.uomCategoryList,
    uomTypeList: (state) => state.uomTypeList,
    paymentMethodCategoryList: (state) => state.paymentMethodCategoryList,
    uomSelect: (state) => {
      const array = state.uomList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        loop.push({
          value: array[i].id,
          label: array[i].name
        })
      }
      return loop
    },
    warehouseSelect: (state) => {
      const array = state.warehouseList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        loop.push({
          value: array[i].warehouse_no,
          label: array[i].name
        })
      }
      return loop
    },
    paymentMethodList: (state) => {
      const array = state.paymentMethodList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        loop.push(array[i].name)
      }
      return loop
    },
    paymentMethodCategorySelect: (state) => {
      const array = state.paymentMethodCategoryList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        const method = array[i].methods
        const methods = []
        for (let a = 0; a < method.length; a++) {
          methods.push({
            id: method[a].id,
            name: method[a].name,
            org_no: method[a].org_no,
            category_id: method[a].category_id,
            active: method[a].active,
            logo: method[a].logo,
            selected: false
          })
        }
        loop.push({
          id: array[i].id,
          name: array[i].name,
          decription: array[i].decription,
          org_no: array[i].org_no,
          methods: methods
        })
      }
      return loop
    }
  }
}
  