import { employeeUrl, getHeader } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    positionList: [],
    departmentList: [],
    employeeList: [],
    activeModalAddEmployee: false,
    activeModalEditEmployee: false,
    activeDepartmentModal: false,
    activeDepartmentEditModal: false,
    activePositionModal: false,
    activePositionEditModal: false,
  },
  getters: {
    employeeList: (state) => state.employeeList
  },
  mutations: {
    SET_POSITION_LIST (state, obj) {
      state.positionList = obj
    },
    SET_DEPARTMENT_LIST (state, obj) {
      state.departmentList = obj
    },
    SET_EMPLOYEE_LIST (state, obj) {
      state.employeeList = obj
    },
    SET_ACTIVE_MODAL_ADD_EMPLOYEE (state, obj) {
      state.activeModalAddEmployee = obj
    },
    SET_ACTIVE_MODAL_EDIT_EMPLOYEE (state, obj) {
      state.activeModalEditEmployee = obj
    },
    SET_ACTIVE_MODAL_DEPARTMENT (state, obj) {
      state.activeDepartmentModal = obj
    },
    SET_ACTIVE_MODAL_DEPARTMENT_EDIT (state, obj) {
      state.activeDepartmentEditModal = obj
    },
    SET_ACTIVE_MODAL_POSITION (state, obj) {
      state.activePositionModal = obj
    },
    SET_ACTIVE_MODAL_POSITION_EDIT (state, obj) {
      state.activePositionEditModal = obj
    }
  },
  actions: {
    activeModalAddEmployee ({ commit }) {
      commit('SET_ACTIVE_MODAL_ADD_EMPLOYEE', true)
    },
    deactiveModalAddEmployee ({ commit }) {
      commit('SET_ACTIVE_MODAL_ADD_EMPLOYEE', false)
    },
    activeModalEditEmployee ({ commit }) {
      commit('SET_ACTIVE_MODAL_EDIT_EMPLOYEE', true)
    },
    deactiveModalEditEmployee ({ commit }) {
      commit('SET_ACTIVE_MODAL_EDIT_EMPLOYEE', false)
    },
    activeModalDepartment ({ commit }) {
      commit('SET_ACTIVE_MODAL_DEPARTMENT', true)
    },
    deactiveModalDepartment ({ commit }) {
      commit('SET_ACTIVE_MODAL_DEPARTMENT', false)
    },
    activeModalEditDepartment ({ commit }) {
      commit('SET_ACTIVE_MODAL_DEPARTMENT_EDIT', true)
    },
    deactiveModalEditDepartment ({ commit }) {
      commit('SET_ACTIVE_MODAL_DEPARTMENT_EDIT', false)
    },
    activeModalPosition ({ commit }) {
      commit('SET_ACTIVE_MODAL_POSITION', true)
    },
    deactiveModalPosition ({ commit }) {
      commit('SET_ACTIVE_MODAL_POSITION', false)
    },
    activeModalEditPosition ({ commit }) {
      commit('SET_ACTIVE_MODAL_POSITION_EDIT', true)
    },
    deactiveModalEditPosition ({ commit }) {
      commit('SET_ACTIVE_MODAL_POSITION_EDIT', false)
    },

    async getPositionList({ commit }) {
      try {
        const data = await axios.get(employeeUrl + '/position', { headers: getHeader() })
        commit('SET_POSITION_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getDepartmentList({ commit }) {
      try {
        const data = await axios.get(employeeUrl + '/department', { headers: getHeader() })
        commit('SET_DEPARTMENT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getEmployeeList ({ commit }) {
      try {
        const data = await axios.get(employeeUrl, { headers: getHeader() })
        commit('SET_EMPLOYEE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
  