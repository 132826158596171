export const domain = process.env.VUE_APP_DOMAIN
export const apiDomain = domain + '/api/v1/'
export const timeoutAPP = process.env.VUE_APP_TIMEOUT

export const loginUrl = apiDomain + 'auth/login'
export const companyStartUrl = domain + 'company'
export const domainBookUrl = domain + '/book'

// AUTH Service
export const authServiceUrl = apiDomain + 'auth-service/'
export const organizationUrl = authServiceUrl + 'organization'
export const branchUrl = authServiceUrl + 'branch'
export const userUrl = authServiceUrl + 'user'
export const roleUrl = authServiceUrl + 'role'
export const permissionUrl = authServiceUrl + 'permission'
export const userTokenUrl = authServiceUrl + 'user/token'

// Booking Service
export const bookingServiceUrl = apiDomain + 'booking-service/'
export const customerUrl = bookingServiceUrl + 'customer'
export const employeeUrl = bookingServiceUrl + 'employee'
export const vendorUrl = bookingServiceUrl + 'vendor'
export const bookUrl = bookingServiceUrl + 'book'
export const promoUrl = bookingServiceUrl + 'promo'
export const socialMediaUrl = bookingServiceUrl + 'social-media'
export const accountUrl = bookingServiceUrl + 'account'
export const cashbankUrl = bookingServiceUrl + 'cash-bank'

// Master Service
export const masterServiceUrl = apiDomain + 'master-service/'
export const productUrl = masterServiceUrl + 'product'
export const uomUrl = masterServiceUrl + 'uom'
export const purchaseUrl = masterServiceUrl + 'purchase'
export const warehouseUrl = masterServiceUrl + 'warehouse'
export const taxUrl = masterServiceUrl + 'tax'
export const settingUrl = masterServiceUrl + 'setting'
export const paymentMethodUrl = masterServiceUrl + 'payment-method'
export const cashUrl = masterServiceUrl + 'cash'
export const bankUrl = masterServiceUrl + 'bank'
export const notificationUrl = masterServiceUrl + 'notification'
export const exportUrl = masterServiceUrl + 'export'
export const lockUrl = masterServiceUrl + 'lock'
export const locationUrl = masterServiceUrl + 'location'
export const amenityUrl = masterServiceUrl + 'amenity'

// Unit Service
export const unitServiceUrl = apiDomain + 'unit-service/'
export const unitUrl = unitServiceUrl + 'unit'
export const unitCheckUrl = unitServiceUrl + 'unit/check'

export const reportUrl = apiDomain + 'report'
export const financeUrl = apiDomain + 'booking-service'

export const companyNo = 'C0001'

export const getHeader = function () {
  const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + tokenData.token
  }
  return headers
}